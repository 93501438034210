<template>
    <div class="header">
        <div class="h-wrap">
            <a class="logo" @click="movePagePush('/info')"><img src="@/assets/image/rebuild/ami_logo.png" alt=""></a>
            <ul class="menu menu_center">
                <li class="mentor_list" ><span  @click="movePage('/info?mt=1')">멘토 소개</span></li>
                <li class="letter" :class="{'active' :$route.meta.name === 'Home' ||  $route.meta.name === 'Letter'}"><span  @click="movePage('/')">꿈편지</span></li> <!--class="active" 현재 진입되어있는 페이지에 넣어주기 -->
                <li class="comm" :class="{'active' : $route.meta.name === 'Community'}"><span  @click="movePage('/commu')">멘토&멘티 소통</span></li>
                <li class="mentor" :class="{'active' : $route.meta.name === 'MentorCommunity'}" ><span @click="movePage('/mentor')">멘토끼리 소통</span></li>
                <li class="amidan" :class="{'active' :$route.meta.name === 'Amidan'}"><span @click="movePagePush('/amidan')">아미단활동</span></li>
                <li class="notice" :class="{'active' :$route.meta.name === 'Notice'}"><span @click="movePagePush('/notice')">공지사항</span></li>
                <li class="service" :class="{'active' : ['Faq', 'Terms'].indexOf($route.meta.name) > -1}">
                    <span>서비스정보</span>
                    <ul class="s-menu" >
                        <li  @click="movePagePush('/faq')"><span>FAQ</span></li>
                        <li  @click="movePagePush('/terms')"><span>약관</span></li>
                    </ul>
                </li>
            </ul>
            <div class="info">
                <img src="@/assets/image/rebuild/default.png" v-if="returnProfileImage() === ''">
                <img :src="returnProfileImage()" alt="" v-else width="47" height="47">
                <div class="profile">
                    <div class="p-name"> {{UserInfo.mb_name}} </div>
                    <div class="logout cursor" @click="UserLogout"> 로그아웃 </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import EventBus from "@/utils/event-bus";
    import {mapGetters, mapState} from "vuex";
    import {logout} from "@/mixins/Auth";
    import imageOption from "@/mixins/imageOption";

    export default {
        name: 'AdminHeader',
        components: {},
        mixins: [imageOption],
        props: {
        },
        data() {
            return {
            }
        },
        created(){

        },
        computed: {
            ...mapState({
                UserInfo: state => state.userInfo.UserInfo,
            }),
        },
        methods:{
            movePage(path) {
                if(this.$route.path === path) {
                    return ;
                }
                // this.$router.push(path);
                location.href = path;
            },
            movePagePush(path) {
                if(this.$route.path === path) {
                    return ;
                }
                this.$router.push(path);
            },
            UserLogout() {
                logout();
            },
            openModal() {
                EventBus.$emit('openMemberInfoModal', 1)
            },
            returnProfileImage() {
                return this.UserInfo.hasOwnProperty('Profile') && this.UserInfo.Profile.length > 0 ? `${this.UserInfo.Profile[0].org_url}?${this.setImageOptions(47, 47, 'png')}` : ''
            },
        },
    }

</script>
